<div class="row">
  <div class="card col-12">
    <div class="card-body">
      <app-field-viewer [Campi]="CampiFiltro()" [(Oggetto)]="filtri" (OnChange)="OnFilter($event)"></app-field-viewer>
    </div>
  </div>
  <div class=" " [class]="campi.larghezza ?? 'col-md-6'"  *ngFor="let campi of DashBoardComponent">
    <div class="card   align-items-center" *ngIf="campi.tipo == 'text'" [ngClass]="campi.class" (click)="Click(campi)">
      <div class="card-body text-white align-items-center">
        <div class="d-flex no-block">
          <div class="m-r-20 align-self-center"><img src="assets/images/icon/expense-w.png" alt="Income" /></div>
          <div class="align-self-center">
            <h2 class="text-white m-t-10 m-b-0">{{campi.titolo}}</h2>
            <h1 class="m-t-0 text-white">{{campi.valore}}</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="card" *ngIf="campi.tipo == 'verticalBar' && campi.valore" style="height: calc(50vh - 30px);">
      <div class="card-body text-white align-items-center dark" [id]="campi.id">
        <h5 class="text-dark m-t-10 m-b-0" *ngIf="filtri.inizio">Periodo:&nbsp;{{filtri.inizio | date: 'dd/MM/yyyy'}}&nbsp;-&nbsp;{{filtri.fine | date: 'dd/MM/yyyy'}}</h5>
        <h5 class="text-dark m-t-10 m-b-0">{{campi.titolo}}  <i class="fas fa-camera" (click)="generateImage(campi.id)" *ngIf="campi.id"></i></h5>
        <div style="height: calc(50vh - 10rem); " >
        <ngx-charts-bar-vertical class=" "  [showGridLines]="false" [animations]="false" [yAxis]="true" [xAxis]="true" [showXAxisLabel]="true" [showYAxisLabel]="true" [results]="campi.VerticalBarData" >
        </ngx-charts-bar-vertical>
        </div>
      </div>
    </div>
    <div class="card" *ngIf="campi.tipo == 'lineChart' && campi.valore" style="height: calc(50vh - 30px);">
      <div class="card-body text-white align-items-center dark">
        <h5 class="text-dark m-t-10 m-b-0" *ngIf="filtri.inizio">Periodo:&nbsp;{{filtri.inizio | date: 'dd/MM/yyyy'}}&nbsp;-&nbsp;{{filtri.fine | date: 'dd/MM/yyyy'}}</h5>
        <h5 class="text-dark m-t-10 m-b-0">{{campi.titolo}}  <i class="fas fa-camera" (click)="generateImage(campi.id)" *ngIf="campi.id"></i></h5>
        <canvas baseChart height="130" [id]="campi.id"  [datasets]="campi.LineChartData.dati" [type]="'line'"  [labels]="campi.LineChartData.labels" [options]="campi['linechartOptions'] ?? lineChartOptions"  [legend]="campi.LineChartData?.dati?.length >1"  >
        </canvas>
<!--ng2chart-->
      </div>
    </div>
    <div class="card" *ngIf="campi.tipo == 'torta' && campi.valore" style="height: calc(50vh - 30px);">
      <div class="card-body  align-items-center" [id]="campi.id">
        <h5 class="text-dark m-t-10 m-b-0" *ngIf="filtri.inizio">Periodo:&nbsp;{{filtri.inizio | date: 'dd/MM/yyyy'}}&nbsp;-&nbsp;{{filtri.fine | date: 'dd/MM/yyyy'}}</h5>
        <h5 class="text-dark m-t-10 m-b-0">{{campi.titolo}}  <i class="fas fa-camera" (click)="generateImage(campi.id)" *ngIf="campi.id"></i></h5>
        <div style="height: calc(50vh - 10rem); " >
        <ngx-charts-advanced-pie-chart  class="chart-container" [animations]="false"  [results]="campi.VerticalBarData" >
        </ngx-charts-advanced-pie-chart>
        </div>
      </div>
    </div>
    <div class="card" *ngIf="campi.tipo == 'pieChart' && campi.valore" style="height: calc(50vh - 50px); ">
      <div class="card-body  align-items-center" [id]="campi.id">
        <h5 class="text-dark m-t-10 m-b-0" *ngIf="filtri.inizio">Periodo:&nbsp;{{filtri.inizio | date: 'dd/MM/yyyy'}}&nbsp;-&nbsp;{{filtri.fine | date: 'dd/MM/yyyy'}}</h5>
        <h5 class="text-dark m-t-10 m-b-0">{{campi.titolo}}  <i class="fas fa-camera" (click)="generateImage(campi.id)" *ngIf="campi.id"></i></h5>
        <div style="height: calc(50vh - 10rem); " >
          <ngx-charts-pie-chart class="chart-container"  [animations]="false" [labels]="true" [legend]="true" [results]="campi.VerticalBarData" >
          </ngx-charts-pie-chart>
        </div>

      </div>
    </div>
  </div>
</div>

